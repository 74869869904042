@import '../../../../scss/theme-bootstrap';

.tabbed-block {
  @include content-container;
  margin: 0 auto;
  &__tabs {
    margin-bottom: 20px;
    text-align: center;
    @include breakpoint($medium-up) {
      margin-bottom: 15px;
    }
  }
  &__tab {
    border-bottom: 1px solid $gray;
    color: $color-light-gray;
    cursor: pointer;
    display: inline-block;
    margin: 0 0 0 -4px;
    font-family: $font--subheading;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 10px 2px;
    transition: 0.3s all ease-in-out;
    &:not(.current):hover {
      color: $tabbed-block-inactive-green;
      border-bottom-color: $tabbed-block-inactive-green;
    }
    &.current {
      border-bottom-color: $color-dark-gray;
      color: $color-dark-gray;
    }
    @include breakpoint($medium-up) {
      font-size: 14px;
      padding: 10px 8px 6px;
    }
  }
  &__content {
    @include content-container;
  }
  &__content-item {
    height: 0;
    width: 100%;
    overflow: hidden;
    transition: opacity 1s ease-in-out;
    opacity: 0;
    &.current {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
    .content-block {
      &--split-width {
        margin-top: 0;
      }
    }
  }
  .slick-list .product-grid {
    @include breakpoint($medium-up) {
      padding: 10px;
    }
  }
}
